body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.team-text{
  font-size: 1.3em;
}
.announcement-image{
  height: 30vh;
}
.trainer-image{
  height: 30vh;
}