body {
    padding:0;
    margin: 0;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    /*apply to body and everything except header*/
    background: #EEEEEE;
}
.announcement-card{
    background-color: white;
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}
/* On mouse-over, add a deeper shadow */
.announcement-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.board-table{
    width: 75%;
}
.secondary-text{
    color:#364859
}